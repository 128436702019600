@import "src/vendors/colors";
@import "src/vendors/mixins";

.filter-creation-keywords-step {
  margin-top: 50px;
  padding: 0 70px 10px 70px;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__keywords-search {
    flex: 1;
    min-width: 600px;
  }


  &__search-row {
    display: flex;
  }

  &__search-input {
    flex: 1;

    display: flex;
    justify-content: space-between;

    .input {
      flex: 1;
    }
    .input__field {
      height: 48px;
      border-radius: 8px 0 0 8px;
      width: 100%;
    }
  }

  &__search-button {
    border-radius: 0 8px 8px 0;
    height: 48px;
  }


  &__preview-header {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    color: #1B1E22;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    margin-top: 30px;

    .button {
      font-size: 12px;
      width: 115px;
      background: rgba(219, 226, 254, 0.41);
      color: $primary-main-color;

      &:hover {
        background-color: $primary-main-color;
        color: #fff;
        opacity: .7;
      }
    }
  }

  &__footer {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}