.accordion {
  padding: 15px;
  padding-left: 30px;
  border-radius: 10px;
  border: 1px solid rgba(99, 99, 99, 0.21);

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &-header {
    display: flex;
    align-items: center;
    position: relative;

    &__lock-icon {
      margin: auto 0 auto auto;

      &:hover {
        cursor: pointer;

        svg {
          path {
            stroke: #221CB6;
          }
        }
      }
    }

    &.blocked {
      .slide-toggler.slide-toggler--active {
        background-color: #DBE2FE;
      }
    }

    &-title {
      margin: 0 8px 0 12px;
      font-weight: 600;
      font-size: 18px;
    }

    &-info {
      border-radius: 5px;
      background-color: #E9E9E9;
      padding: 5px;
      height: 21px;
      display: flex;
      align-items: center;

      span {
        margin-left: 5px;
        display: inline-block;
        font-size: 10px;
        color: #4B4C4D;
      }
    }

    .icon-arrow {
      position: absolute;
      right: 0;

      &.active {
        transform: rotate(180deg);
        transition: .3s;
      }
    }
  }

  &-content-body {
    &__started-plan {
      padding: 0 40px;
      min-height: 240px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-title {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &-text {
        color: rgba(0, 0, 0, 0.59);
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 162.5%;
        max-width: 280px;
        margin: 0 auto 23px;
      }

      &-button {
        margin: 0 auto;
        width: 228px;
        height: 43px;
      }
    }
  }

  .accordion-content-body-list {

    &__item {
      .react-tooltip--header-date-filter {
        background-color: #000000 !important;

        .rdrCalendarWrapper {
          .rdrMonthsVertical {
            .rdrDays {
              .rdrDay {
                color: white !important;
              }
            }
          }
        }
      }
    }

    &__item-label {
      color: #202430;
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      margin-right: 16px;
    }

    &__item-block {
      margin-left: 20px;
      display: flex;
      align-items: center;
      gap: 8px;

      &__title {
        display: flex;
      }
    }
  }


  &-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__item {
      border-radius: 6.537px;
      border: 0.817px solid rgba(99, 99, 99, 0.21);
      background: #FFF;
      padding: 10px;
      padding-left: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 220px;
      width: 100%;
      position: relative;

      &:not(:last-child) {
        margin-right: 13px;
      }

      svg {
        position: absolute;
        left: 15px;
      }

      .text-green {
        color: #58CD95;
      }

      .text-blue {
        color: #251FB6;
      }

      .text-red {
        color: #ED483D;
      }

      &-tooltip {
        max-width: 220px;
        padding: 6px;
      }
    }
  }
}
