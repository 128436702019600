@import "src/vendors/mixins";
@import "src/vendors/colors";

.relationships-pipelines {
  &__filters-button {
    display: flex;
    padding: 12px;
    color: #7B7B7B;
    background-color: $primary-grey-action-color;
    border-radius: 8px;
    height: 45px;

    &:hover {
      color: #474747;
    }

    svg {
      margin-right: 10px;
    }
  }

  &__column-container {
    display: flex;
    align-items: flex-start;

    .add-button {
      padding: 0;
      margin-top: 38px;
      margin-right: 15px;

      span {
        color: $grey-black-color;
      }

      &:hover {
        span {
          color: $primary-main-color;
        }

        svg {
          @include svg-colors-path($primary-main-color);
        }
      }
    }
  }

  &__columns {
    display: flex;
    height: calc(100vh - 145px);
    padding: 25px 15px;
    overflow-x: auto;
  }

  .page-header {
    .campaigns-header__actions-wrapper {
      .header__search {
        .input__field {
          width: 100%;
        }
      }
    }
  }
}

.relationships-pipelines__mock {
  position: relative;

  .relationship-board-modal {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 49px;
      max-width: 493px;
      background-color: #FFFFFF;
      width: 100%;
      position: relative;
      top: 50%;
      left: 50%;
      border-radius: 19px;
      transform: translateY(-50%) translateX(-50%);

      &-image {
        width: 156px;
        height: 183px;
        margin-bottom: 33px;
      }

      &-title {
        color: #000;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        margin-top: 0;
      }

      &-info {
        color: #000;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        max-width: 285px;
        line-height: 178.5%;
        margin-bottom: 23px;
      }

      &-button {
        width: 186px;
        height: 45px;
      }
    }

    &_background {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(79, 79, 79, 0.29);
      backdrop-filter: blur(4px);
    }
  }
}
