.inquiries-page {
  display: flex;
  height: 100vh;

  &__page-content {
    flex: 1;
  }

  &__content {
    height: calc(100vh - 86px);
    overflow-y: auto;
    min-width: 1000px;
  }
}