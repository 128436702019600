.contact-sidebar-crm-section {
  display: flex;
  flex-direction: column;

  border-top: 1px solid #ebebeb;

  background-color: #ffffff;

  &__drag-icon {
    position: absolute;
    top: 25px;
    left: 13px;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;

    padding: 20px 28px 20px;

    &-title {
      padding-right: 8px;

      font-weight: 600;
      font-size: 18px;
      line-height: 20px;

      color: #202430;

      cursor: pointer;
    }

    &-collapse-btn {
      padding: 4px;
      cursor: pointer;

      transform: rotate(180deg) translateY(-3px);

      &--open {
        transform: rotate(0) translateY(0);
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow-y: auto;

    transition: max-height 280ms ease;

    &--open {
      max-height: unset;
      overflow-y: visible;
    }
  }

  &__content {
    padding: 12px 20px 28px;
  }
}
