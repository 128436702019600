.opportunity-contacts-sidebar-crm-section {
  display: flex;
  flex-direction: column;

  border-top: 1px solid #ebebeb;

  background-color: #ffffff;

  &__drag-icon {
    position: absolute;
    top: 25px;
    left: 13px;
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;

    padding: 12px 18px 12px;

    &-title {
      padding-right: 8px;

      font-weight: 600;
      font-size: 16px;
      line-height: 20px;

      color: #202430;

      cursor: pointer;
    }

    .opportunity-contacts-sidebar-crm-section__drag-icon {
      position: absolute;
      top: 15px;
      left: 7px;

      svg {
        width: 8px;
        height: 8px;
      }
    }

    &-right-component {
      .opportunity-contacts-sidebar-crm__content__add-btn {
        font-size: 12px;
        color: #221CB6;
        line-height: 16px;
        padding: 4px 10px;
        top: 14px;
        right: 10px;
        position: absolute;
        border-radius: 8px;
        font-weight: 600;
        cursor: pointer;

        & > svg {
          margin-right: 4px;
        }

        &:hover {
          background-color: #f1f4ff;
        }
      }
    }

    &-collapse-btn {
      padding: 4px;
      cursor: pointer;

      transform: rotate(180deg) translateY(-3px);

      &--open {
        transform: rotate(0) translateY(0);
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow: hidden;

    transition: max-height 280ms ease;

    &--open {
      max-height: unset;
    }
  }

  &__content {
    padding: 12px 20px 28px;
  }
}
