@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiry-details {
  background: #F5F6FA;
  border-radius: 15px;
  padding: 20px 20px 10px 20px;
  position: relative;
  display: flex;
  justify-content: space-between;

  &__details {
    width: 60%;
  }

  &__actions {
    width: 30%;
    display: flex;
    align-items: flex-start;
  }

  &__add-bookmark-btn {
    border: 1px solid rgba(193, 193, 193, 0.32);
    border-radius: 11px;
    width: 45px;
    min-width: 45px;
    height: 45px;
    margin-right: 16px;

    &:hover {
      background-color: #E9EEFF;
    }
  }

  &__pitch-btn {
    height: 45px;
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    color: #1B1E22;
    margin-bottom: 14px;
  }

  &__info {
    @include mulish-text;
    font-size: 14px;
    line-height: 25px;
    color: #1B1E22;
    margin-bottom: 25px;
  }

  &__hide-btn {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 80px);
    cursor: pointer;
  }

  &__hide-btn-content {
    width: 160px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding: 8px 17px;
    align-items: center;
    color: #BBBDBE;
    font-weight: 800;
    font-size: 10px;

    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 48px;

    svg {
      transform: rotateZ(-90deg);
    }
  }
}