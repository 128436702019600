@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.campaign-settings-verification {
  &__header {
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 16px;
    padding: 30px 0;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    color: #000000;
  }

  &__check-row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    height: 26px;

    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;

    .checkbox {
      margin-right: 8px;
      cursor: pointer;

      .checkbox__field {
        border-color: inherit;
      }

      .checkbox__field:disabled {
        border-color: #c6c6c6;
      }
    }

    .input__field {
      width: 50px;
      height: 30px;
      padding-left: unset;
      text-align: center;
      margin: 0 5px;
      border: 1px solid #dbdfe7;
      border-radius: 8px;

      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
    }

    &-tooltip {
      white-space: normal;
      margin-left: 8px;
    }
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__review-step {
    &__cards {
      display: flex;
      align-items: center;
      gap: 13px;
    }

    &__process {
      padding: 0 0 25px;
    }

    &__flagged-items {
      display: flex;
      flex-direction: row;
      font-weight: 500;
      gap: 6px;

      &__download {
        text-decoration: underline;
        cursor: pointer;
        color: $primary-main-color;
      }
    }

    &__action {
      width: 26px;
      padding-left: 8px;
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #edeff7;

      cursor: pointer;

      &:hover {
        svg {
          @include svg-colors-path-fill($third-light-blue-color);
        }
      }
    }
  }

  &__review-card {
    flex: 1;
    height: 48px;

    background-color: #ffffff;
    border: 1px solid #DBDFE7;
    border-radius: 8px;

    display: flex;
    align-items: center;

    padding: 15px;

    &__title {
      font-weight: 600;
      font-size: 16px;

      margin-left: 10px;
    }

    &__number {
      margin-left: auto;
    }
  }

  &__footer {
    position: fixed;

    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 603px;
    bottom: 0;

    padding: 20px 0 40px;
    background-color: #ffffff;
  }
}
