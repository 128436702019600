@import 'src/vendors/mixins.scss';
@import 'src/vendors/colors.scss';

.button {
  @include mulish-text;
  width: 200px;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  border: none;

  font-weight: 500;
  font-size: 16px;
  line-height: 130%;

  &:focus {
    outline: none;
  }

  &--primary {
    background-color: $primary-main-color;
    color: #ffffff;

    &:hover {
      background-color: #4e48dc;
    }
  }

  &--secondary {
    background-color: $primary-grey-action-color;
    color: $grey-black-color;

    &:hover {
      opacity: 0.8;
    }
  }

  &--graph {
    background-color: #E9E9E9;
    color: #BDBDBD;
    min-width: 36px;
    height: 36px;
  }

  &--white {
    background-color: #ffffff;
    color: $primary-black-color;

    &-shadow {
      background-color: #ffffff;
      box-shadow: 0px 4px 4px 0px #0000000F;

    }
  }

  &--black {
    background-color: $secondary-black-color;
    color: $light-main-color;
  }

  &--grey {
    background: $secondary-black-color;
    color: $grey-black-color;
  }

  &--blue {
    background: #D9E3FF;
    color: #3424EB;
  }

  &--bordered {
    border: 1px solid $primary-main-color;
    color: $primary-main-color;
    background-color: transparent;

    &:hover {
      background-color: #4e48dc;
      color: #ffffff;
    }

    &.button--link {
      &:hover {
        color: #ffffff;
      }
    }
  }

  &--bordered-grey {
    border: 1px solid $border-grey-color;
    color: $grey-text-secondary;
    background-color: transparent;

    &:hover {
      border-color: $grey-text-secondary;
    }
  }

  &--show-more {
    background-color: #ffffff;
    border: 1px solid #8C8D8D69;
  }

  &--ghost {
    display: inline-flex;
    border-radius: 0;
    width: auto;
    height: auto;
    background-color: transparent;
    padding: 0;
    border: none;
    color: #7974f7;

    &:hover {
      color: #4e48dc;
    }
  }

  &--additional {
    background-color: #e9eeff;
    color: #231db6;

    &:hover {
      background-color: #231db6;
      color: #e9eeff;

      svg {
        filter: brightness(600%);
      }
    }
  }

  &--alert {
    background: $alert-color;
    color: #fff;

    &:hover {
      background: $alert-hover-color;
    }
  }

  &--confirm {
    background: $success-color;
    color: #fff;

    &:hover {
      background: $success-color;
      opacity: .9;
    }
  }

  &--disabled {
    opacity: 0.7;
    cursor: not-allowed;

    &:hover {
      opacity: 0.7;
    }
  }

  &--link {
    text-decoration: none;
  }

  &--size {
    &-xs {
      width: auto;
      padding-right: 4px;
      padding-left: 4px;
    }

    &-s,
    &-m {
      width: auto;
      font-size: 16px;
      min-width: 82px;
    }

    &-s {
      padding-right: 12px;
      padding-left: 12px;
      height: 35px;
    }

    &-m {
      padding-right: 19px;
      padding-left: 19px;
      height: 40px;
    }

    &-l {
      padding-right: 15px;
      padding-left: 15px;
      height: 45px;
    }

    &-xl {
      padding-right: 23px;
      padding-left: 23px;
      height: 48px;
    }
  }

  &--inline {
    width: auto;
    min-width: 20px;
    padding-right: 3px;
    padding-left: 3px;
  }

  & > svg:not(:last-child) {
    margin-right: 8px;
  }
}
