@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiry-pitch-email-editor {
  &__meta {
    padding: 25px;
    background-color: #FBFCFD;
    border-bottom: 1px solid $secondary-grey-lines-color;
  }

  &__meta-row {
    @include mulish-text;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $grey-text-secondary;

    &:first-child {
      margin-bottom: 20px;
    }

    &__details {
      box-sizing: border-box;
      border-bottom: 1px solid $secondary-grey-lines-color;
      border-radius: 8px;
      max-height: 360px;
      width: 265px;
      background-color: #000000;
      overflow-x: auto;
      padding: 10px 14px;

      &__row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 6px;

        margin-bottom: 6px;

        &::-webkit-scrollbar {
          display: none;
        }

        &--disabled {
          cursor: not-allowed;
        }
      }

      &__row-buttons {
        margin-top: 12px;
        display: flex;
      }

      &__label {
        @include mulish-text;
        font-size: 14px;
        color: $grey-text-secondary;
        margin-right: 6px;
      }

      &__to-emails {
        display: flex;
        flex-wrap: wrap;

        max-height: 200px;
        overflow-y: scroll;
      }

      &__value {
        @include mulish-text;
        font-size: 14px;
        box-sizing: border-box;
        display: inline-flex;
        align-items: center;
        height: 22px;
        padding: 4px 6px;
        background: $grey-selection-color;
        border-radius: 5px;
        margin-right: 6px;

        cursor: default;

        svg {
          width: 14px;
          height: 14px;
          margin-left: 4px;
          cursor: pointer;
        }

        &--disabled {
        }
      }

      &__remove-icon {
        height: 14px;
      }

      &__adding-button {
        @include mulish-text;
        font-size: 12px;
        margin-right: 12px;
        color: $secondary-blue-color;
        cursor: pointer;
      }

      &__chevron {
        cursor: pointer;

        svg {
          transform: rotateX(180deg);
        }
      }

      &__contact {
        padding: 10px 14px;
        border-top: solid 1px #272727;

        display: flex;
        align-items: center;

        cursor: pointer;

        &:hover {
          background-color: #181818;
        }
      }

      &__contacts-search input {
        height: 45px;
        width: 100%;
        background-color: #000000;
        border: 1px solid #272727;
        border-radius: 6px;

        color: #ffffff;

        &:focus {
          border-color: #272727;
        }
      }
    }
  }

  &__meta-value {
    @include mulish-text;
    padding: 4px 6px;
    background: $grey-selection-color;
    border-radius: 5px;
    margin-left: 6px;
  }

  &__subject-row {
    @include mulish-text;
    color: $grey-text-secondary;
    display: flex;
    height: 58px;
    align-items: center;
    padding-left: 28px;
    border-bottom: 1px solid $secondary-grey-lines-color;
    background-color: #fff;

    .input__field {
      width: 100%;
      padding-left: 0px;
    }
  }

  &__subject-input {
    margin-right: 10px;
    width: 95%;
  }
  
  &__template {
    background-color: #fff;
  }
}
