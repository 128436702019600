@import "src/vendors/mixins";

.haro-category {
  height: 31px;
  border-radius: 42px;
  @include mulish-text;
  font-size: 12px;
  line-height: 14px;
  padding: 0 10px;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}