@import "src/vendors/mixins";

.personalize-campaign-builder {
  position: relative;
  display: flex;

  &__content {
    display: flex;
    width: 100%;
  }

  &__button-loader {
    position: absolute;
    right: 0;
    top: -70px;
  }

  .article-summary {
    background-color: transparent;
  }
}

.personalize-campaign-builder-empty {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__message {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 15px;
    text-align: center;
  }

  &__button {
    width: 199px;
  }
}
