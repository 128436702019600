@import 'src/vendors/mixins';

.sidebar-crm {
  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    padding-bottom: 48px;

    &-header {
      padding: 7px 18px 20px;

      .sidebar-crm-header-name {
        font-size: 14px;
        font-weight: normal;
      }
    }

    &__add-btn {
      position: absolute;
      top: 19px;
      right: 18px;

      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #221cb6;
      border-radius: 8px;
      padding: 3px 10px;

      cursor: pointer;

      &:hover {
        background-color: #f1f4ff;
      }

      & > svg {
        margin-right: 4px;
      }
    }

    &-personal-info {
      position: relative;

      display: flex;
      flex-direction: column;

      padding: 0;
      gap: 10px;

      &-label {
        margin-top: 15px;
        margin-bottom: 3px;

        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #7b7b7b;
      }

      &-data-row {
        display: flex;
        align-items: center;

        margin-bottom: 8px;

        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        color: #000000;

        &-social > div {
          margin-bottom: 0;
          width: 100%;
        }

        &-social > span,
        &-social-link > span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 435px;
        }

        &-social-link:hover {
          cursor: pointer;
          color: #221cb5;

          & > svg > path {
            fill: #221cb5;
          }
        }

        & > svg {
          flex-shrink: 0;
          margin-left: -2px;
          margin-right: 6px;
        }
      }
    }

    .inbox-content-wrapper {
      gap: 12px;
      padding: 19px 20px 21px;
      border: 1px solid #dce1e4;
      border-radius: 8px;

      &.flex-content {
        display: flex;
        flex-direction: column;
      }

      &__content {
        display: flex;
        align-items: center;
        position: relative;
        gap: 8px;
        width: fit-content;

        &:has(.inbox-content-wrapper__content-title) {
          width: 100%;
        }

        &-title {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          color: #000000;
          position: relative;

          span {
            width: fit-content;
          }

          & + .contact-sidebar-crm__content-personal-info-data-row {
            margin-left: 0;
            margin-bottom: 5px;

            &:hover {
              cursor: pointer;

              svg {
                @include svg-colors-path($secondary-blue-color)
              }
            }
          }

          &:hover {
            cursor: pointer;
            color: $secondary-blue-color;
          }
        }

        .opportunity-contacts-sidebar-section-contacts__contacts-contact-title-close {
          position: absolute;
          right: -8px;
          top: 6px;

          &:hover {
            cursor: pointer;

            svg {
              @include svg-colors-path($alert-color);
            }
          }
        }


        & > .sidebar-crm-section__editable-row {
          font-size: 16px;
          font-weight: 600;
        }

        &__delete-btn {
          position: absolute;
          right: 0;

          &:hover {
            svg {
              @include svg-colors-path-fill(#ed483d);
              cursor: pointer;
            }
          }
        }
      }

      &__data {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #000000;

        svg {
          align-self: flex-start;
          margin-top: 1px;
          margin-right: 9px;
          flex-shrink: 0;
        }

        &-email {
          width: 100%;
          word-break: break-all;
        }

        & + .contact-sidebar-crm__content-personal-info-data-row {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .sidebar-crm-section__editable-row {
        span {
          width: fit-content;
        }
      }
    }

    .sidebar-crm__content-personal-info-data-row {
      margin-bottom: 2px;

      &.social-link-info {
        width: 100%;
      }

      &-social {
        &:hover {
          transition: .3s;
          cursor: pointer;

          svg {
            @include svg-colors-path($secondary-blue-color);
          }
        }
      }
    }
  }
}
