@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.inquiries-sidebar {
  width: 210px;
  min-width: 210px;
  height: 100vh;
  background-color: $grey-bg-color;
  display: flex;
  flex-direction: column;

  &__header {
    padding: 17px 14px 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #7b7b7b;
  }

  &__pages {
    padding: 0 9px 17px 9px;
  }

  &__page-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 12px;
    cursor: pointer;

    &--active {
      color: $secondary-blue-color;
      background: $grey-selection-color;
      border-radius: 8.5px;
    }
  }

  &__page-link-href {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    height: 44px;
    text-decoration: none;
    color: inherit;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__page-link-icon {
    height: 16px;
    margin-right: 7px;
    display: flex;
    align-items: center;
  }

  &__separator {
    height: 1px;
    background-color: #ededed;
    margin: 12px 0;
  }

  &__sub-title {
    @include mulish-text;
    font-size: 16px;
    line-height: 20px;
    color: $grey-black-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 5px;
    margin-bottom: 15px;
  }

  &__sub-title-action-icon {
    cursor: pointer;
    height: 20px;

    &:hover {
      @include svg-colors-path-fill($secondary-black-color);
    }
  }

  &__footer {
    margin-top: auto;
    position: relative;
    display: flex;
    border-top: 1px solid rgba(189, 189, 189, 0.31);
  }

  &__footer-button-icon {
    margin-right: 5px;
  }

  &__footer-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 12px;
    color: #8f9199;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      opacity: 0.8;
    }

    &--bookmarks {
      width: 50%;
      border-right: 1px solid rgba(189, 189, 189, 0.31);
    }

    &--pitched {
      flex: 1;
    }

    &--active {
      background-color: #e9eeff;
      color: $primary-main-color;
    }
  }
}
