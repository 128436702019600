@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiry-pitch {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #F9F9F9;
  overflow-y: auto;
  z-index: 10;

  &__header {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 23px;
    position: relative;
    border-bottom: 1px solid #F4F4F4;
  }

  &__title {
    max-width: 85%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content-wrapper {
    display: flex;
  }

  &__info-wrapper {
    flex: 1;
    padding-bottom: 30px;
  }

  &__bookmark-row {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  &__separator {
    flex: 1;
    height: 1px;
    background-color: #F4F4F4;
    margin-right: 23px;
  }

  &__add-bookmark-btn {
    border: 1px solid rgba(193, 193, 193, 0.32);
    border-radius: 11px;
    width: 45px;
    min-width: 45px;
    height: 45px;
    margin-right: 16px;

    &:hover {
      background-color: #E9EEFF;
    }
  }

  &__info,
  &__info-header {
    padding-right: 30px;
    padding-left: 50px;
  }

  &__info-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    color: #1B1E22;
    margin-top: 50px;
    margin-bottom: 12px;
  }

  &__info-content {
    @include mulish-text;
    font-size: 14px;
    line-height: 25px;
    color: #1B1E22;
  }

  &__email {
    width: 611px;
    max-width: 611px;
  }
}
