@import 'src/vendors/colors';

.haro-reminder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__image {
    width: 336px;
  }

  &__header {
    font-weight: 600;
    margin-top: 60px;
    margin-bottom: 0;
  }

  &__text {
    margin-top: 30px;
    margin-bottom: 0;
    color: #1B1E22;
    font-size: 14px;
  }

  &__user-email {
    margin-top: 15px;
    margin-bottom: 0;
    color: $primary-main-color;
  }

  &__create-btn {
    margin-top: 26px;
  }

  &__skip {
    opacity: 0.6;
    cursor: pointer;
    margin-top: 12px;
  }

  a {
    color: $light-main-color;
    text-decoration-line: none;
  }
}



