.contacts-sidebar-section-notes {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &--empty {
      color: #7b7b7b;
      font-size: 14px;
    }
  }

  &__note {
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 15px 17px 14px;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    background-color: #fffcee;

    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    word-break: break-word;

    color: #000000;

    &:hover &-header {
      display: flex;
    }

    &-header {
      display: none;
      position: absolute;

      align-items: center;
      gap: 13px;

      top: 2px;
      right: 10px;

      span {
        cursor: pointer;
      }
    }

    &-textarea {
      textarea {
        font-size: 14px;
        line-height: 23px;
        min-height: 100px;
        max-height: 300px;
        max-width: 100%;
        min-width: 100%;

        border-radius: 0;
        padding: 0;

        background-color: #fffcee;
        border: none;

        &:focus {
          border: none;
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 28px;

    &-owner {
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #868686;

      &-logo {
        width: 22px;
        height: 22px;
      }

      span {
        padding-left: 9px;
      }
    }

    &-time {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #868686;
    }

    &-cancel-btn {
      height: 28px;
      margin-right: 6px;

      font-size: 12px;
      line-height: 12px;
      color: #8f9199;

      &:hover {
        border: 1px solid #8f9199;
      }
    }

    &-btn {
      height: 28px;
      font-size: 12px;
      line-height: 12px;
      color: #ffffff;
    }
  }
}
