@import "src/vendors/mixins";
@import "src/vendors/colors";

.filter-creation-bio-step {
  margin-top: 30px;
  padding: 0 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__grid {
    display: grid;
    grid-template-columns: 315px 315px;
    grid-template-rows: 75px 75px 75px 75px 75px;
    justify-content: space-between;
    grid-gap: 30px;
  }

  &__field-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    margin-bottom: 10px;
  }

  &__field-error {
    @include mulish-text;
    font-size: 12px;
    color: $alert-color;
    margin-bottom: 8px;
  }

  &__footer {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}