@import 'src/vendors/mixins';
@import 'src/vendors/variables';

.page-header {
  height: $page-header-height;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: relative;
  z-index: 2;
  background: white;

  &:after {
    position: absolute;
    content: '';
    background-color: #f4f4f4;
    bottom: 0;
    left: 20px;
    right: 20px;
    height: 1px;
  }

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
  }

  &__title-with-how-to {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
  }

  &__title-with-backarrow {
    display: flex;
    align-items: center;

    .back-arrow-button {
      width: 26px;
      height: 26px;
      border-radius: 5px;
      margin-right: 11px;

      svg {
        flex-shrink: 0;
      }
    }

    .back-arrow-button:hover {
      background-color: #e5e4f6;

      svg {
        @include svg-colors-path-fill(#251fb6);
      }
    }
  }

  &__actions {
    margin-left: auto;
  }
}
