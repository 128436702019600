.campaigns-page {
  display: flex;
  height: 100vh;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 68px;

    .page-header {
      position: fixed;
      top: 0;
      right: 0;
      left: 90px;
      z-index: 20;
    }
  }
}
