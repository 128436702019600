.contacts-sidebar-crm-section {
  &__selection-row {
    display: flex;
    align-items: center;

    padding: 3px 5px 3px;

    margin-left: -5px;
    margin-right: -5px;
    margin-bottom: 5px;

    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    color: #000000;

    span {
      width: 87%;
      margin-right: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .selected-status {
      width: 87%;
      margin-right: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      svg {
        margin-right: 9px;
      }
    }

    .select {
      width: 95%;
    }

    .input {
      width: 100%;
    }

    input {
      border: none;
      font-size: 14px;
      height: 22px;
      width: 100%;
      padding-left: 0;
      background-color: transparent;
      border: 0 !important;
    }

    &--is-editing {
      display: flex;
      justify-content: space-between;
    }

    &:hover,
    &--is-editing {
      background-color: rgba(219, 226, 254, 0.38);
      border-radius: 7px;
    }
  }

  &__selection-row:hover &__editable-btn {
    display: flex;

    svg {
      margin-right: 0;
    }
  }

  &__editable-btn {
    display: none;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #ffffff;

    cursor: pointer;

    &--refreshing svg {
      animation: rotate 1s linear infinite;
    }

    &:first-of-type svg {
      margin-top: 1px;
    }
  }

  &__editable-btn-row {
    display: flex;
    gap: 3px;
  }
}
