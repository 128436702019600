@import 'src/vendors/mixins';

.contact-sidebar-crm {
  width: 526px;

  &__container {
    z-index: 10;
  }

  &__body {
    overflow: auto;
  }

  &__close-icon {
    top: 38px;
  }

  &__show-more-btn {
    width: 115px;
    margin-top: -1px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;

    color: #221cb6;

    margin-left: -5px;

    border-radius: 8px;
    padding: 3px 10px;

    cursor: pointer;

    &:hover {
      background-color: #f1f4ff;
    }

    svg {
      margin-left: 7px;
      transform: rotate(180deg);
      path {
        fill: #221cb6;
      }
    }

    &.show-less {
      transition: .3s;

      svg {
        transform: rotate(0);
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    padding-bottom: 48px;

    &-header {
      padding: 7px 18px 20px;

      .contact-sidebar-crm-header-name {
        font-size: 14px;
        font-weight: normal;
      }
    }

    &__add-btn {
      position: absolute;
      top: 19px;
      right: 18px;

      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #221cb6;
      border-radius: 8px;
      padding: 3px 10px;

      cursor: pointer;

      &:hover {
        background-color: #f1f4ff;
      }

      & > svg {
        margin-right: 4px;
      }
    }

    &-personal-info {
      position: relative;

      display: flex;
      flex-direction: column;

      padding: 0;
      gap: 10px;

      &-label {
        margin-top: 15px;
        margin-bottom: 3px;

        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #7b7b7b;
      }

      &-data-row {
        display: flex;
        align-items: center;

        margin-bottom: 8px;

        font-weight: 500;
        font-size: 14px;
        line-height: 22px;

        color: #000000;

        &-social > div {
          margin-bottom: 0;
          width: 100%;
        }

        &-social > span,
        &-social-link > span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 435px;
        }

        &-social-link:hover {
          cursor: pointer;
          color: #221cb5;

          & > svg > path {
            fill: #221cb5;
          }
        }

        & > svg {
          flex-shrink: 0;
          margin-left: -2px;
          margin-right: 6px;
        }
      }
    }

    .inbox-content-wrapper {
      gap: 12px;
      padding: 19px 20px 21px;
      border: 1px solid #dce1e4;
      border-radius: 8px;

      &__content {
        display: flex;
        align-items: center;
        position: relative;
        gap: 8px;
        width: fit-content;


        & > .contacts-sidebar-crm-section__editable-row {
          font-size: 16px;
          font-weight: 600;
        }

        &__delete-btn {
          position: absolute;
          right: 0;

          &:hover {
            svg {
              @include svg-colors-path-fill(#ed483d);
              cursor: pointer;
            }
          }
        }
      }

      .contacts-sidebar-crm-section__editable-row {
        span {
          width: fit-content;
        }
      }
    }

    .contact-sidebar-crm__content-personal-info-data-row {
      margin-bottom: 2px;

      &.social-link-info {
        width: 100%;
      }

      &-social {
        &:hover {
          transition: .3s;
          cursor: pointer;

          svg {
            @include svg-colors-path($secondary-blue-color);
          }
        }
      }
    }
  }

  &__content-personal-info:not(:last-child) {
    margin-bottom: 12px;
  }
}

.contact-sidebar-crm--small {
  .contact-sidebar-crm-section__content {
    padding: 0;
  }
  .contact-sidebar-crm-header {
    padding: 20px 18px 19px;

    border-bottom: 1px solid #ececec;

    &-column {
      gap: 4px;
    }

    &-name {
      font-size: 14px;
      line-height: 22px;
    }

    &-company {
      font-size: 12px;
      line-height: 12px;
      padding-left: 0;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  .contact-sidebar-crm {
    &__content {
      padding-bottom: 48px;

      &__add-btn {
        top: 14px;
        right: 10px;

        font-size: 12px;
        line-height: 16px;
        padding: 4px 10px;
        & > svg {
          width: 9px;
          height: 9px;
        }
      }

      &-personal-info {
        padding: 7px 18px 20px;

        &-label {
          font-size: 12px;
          line-height: 19px;
        }
      }
    }
  }

  .contact-sidebar-crm-section {
    &__content-container--open {
      padding: 7px 18px 20px;
    }

    &.inbox-contact-section {
      .contact-sidebar-crm-section__content-container {
        padding: 0;
      }
    }

    &__header {
      padding: 12px 18px 12px;
      &-title {
        font-size: 16px;
        line-height: 16px;
      }
      &-collapse-btn svg {
        width: 10px;
      }
    }

    &__drag-icon {
      top: 15px;
      left: 7px;
      svg {
        width: 8px;
        height: 8px;
      }
    }
  }

  .contacts-sidebar-section-websites__website {
    padding: 12px 12px;
  }
  .contacts-sidebar-section-websites__contacts-contact {
    padding: 12px 12px;
  }
  .contacts-sidebar-section-campaigns__campaign {
    padding: 12px 12px;
  }
  .contacts-sidebar-section-opportunities__opportunity {
    padding: 19px 20px 21px;
  }
  .contacts-sidebar-section-notes__note {
    padding: 8px 12px;
  }

  .contacts-sidebar-section-emails__email-title {
    max-width: 100%;
    width: 100%;
    margin-top: 12px;

    font-size: 14px;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .contacts-sidebar-section-emails__email-date {
    font-size: 12px;
  }
}
