@import "src/vendors/colors";

.template-editor-page {
  display: flex;
  flex-direction: column;

  &__header-btns {
    display: flex;

    .button {
      margin-left: 8px;
      height: 45px;
    }
  }

  &__body {
    padding: 30px;
  }

  &__date-created {
    color: $grey-text-secondary;
    position: relative;
    top: 30px;

    &-text {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &-autor {
      background-color: $button-light-blue-color;
      color: $secondary-blue-color;
      font-size: 12px;
      border-radius: 8.5px;
      width: fit-content;
      padding: 5px;
      min-width: 70px;
      text-align: center;
    }
  }

  &__sequence-editor {
    display: flex;
    justify-content: space-between;
  }


  &__title-input-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .input__field {
      height: 50px;
      width: 350px;
      border-color: #E9ECF1;
    }
  }


  &__editor {
    flex: 1;
    display: flex;
    flex-direction: column;


    .sequence-editor {
      background-color: #FBFCFD;
      border-radius: 8px;

      &__autosave {
        margin-top: 15px;
      }
    }
  }

  &__steps {
    overflow-y: auto;
    border-radius: 8px;
    margin-right: 12px;
    width: 33%;
    min-width: 350px;     // We need min-width 350px for footer buttons in template select
    max-width: 390px;
    display: flex;
    flex-direction: column;
    margin-top: 60px;

    background: #FBFCFD;
    border-radius: 8px;
  }
}
