@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiry-pitch-summary-info {
  --colmun-width: 315px;
  display: grid;
  grid-template-columns: var(--colmun-width) var(--colmun-width);
  grid-template-rows: 25px 25px 25px;

  grid-gap: 20px 45px;
  margin-top: 35px;

  &__data {
    display: flex;
    align-items: center;
  }

  &__data-label,
  &__data-value {
    @include  mulish-text;
    font-size: 14px;
    line-height: 25px;
    color: #1B1E22;
    white-space: nowrap;
    margin-right: 5px;
  }

  &__data-label {
    font-weight: 500;
    margin-right: 5px;
  }

  &__data-value {
    margin: 0;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__data-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
}

@media screen and (max-width: 1439px) {
  .inquiry-pitch-summary-info {
    grid-template-columns: var(--colmun-width);
    grid-template-rows: 25px 25px 25px 25px 25px 25px;
  }
}

