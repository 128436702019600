@import 'src/vendors/mixins';
@import 'src/vendors/colors';

.inbox-widget {
  position: fixed;
  right: 30px;

  width: 530px;
  min-height: 460px;
  max-height: 700px;

  background-color: #ffffff;
  border-radius: 8px;

  box-shadow: #cccccc 1px 0 20px 0;
  animation: popup 0.5s linear forwards;

  display: flex;
  flex-direction: column;

  z-index: 1000;

  &__spinner {

    svg {
      animation: spin 1000ms;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }

    @keyframes spin {
      from {
        transform: rotate(0turn);
      }
      to {
        transform: rotate(1turn);
      }
    }
  }

  &--embedded {
    position: relative;
    top: 0;
    bottom: auto;
    right: auto;
    left: auto;
    min-height: unset;
    max-height: unset;
    width: 100%;
    margin: 20px 0;
    box-shadow: none;

    z-index: unset;

    .inbox-widget__editor {
      min-height: unset;
      max-height: unset;
    }
  }

  &__close {
    @include right-close-icon(20px, 3px, black);
    top: -40px;
  }

  &__type {
    font-size: 14px;
    color: $grey-text-color;
    padding: 8px 8px 8px 18px;
  }

  &__editor-subject {
    border-bottom: 1px solid $secondary-grey-lines-color;
    position: relative;

    .input {
      &__field {
        border: none;
        border-radius: 0;
        padding-left: 28px;
        padding-right: 28px;
      }
    }
  }

  &__editor {
    min-height: 300px;
    max-height: 500px;

    padding: 20px 28px;

    overflow-y: scroll;
  }

  &__signature {
    margin-bottom: 12px;
  }

  &__attachments {
    border-top: 1px solid #e9ecf1;
  }

  &__show-original-btn {
    padding: 0;
    min-width: 0;

    &:hover {
      svg {
        @include svg-colors-path($primary-black-color);
      }
    }
  }

  &__original-message {
    box-sizing: border-box;
    padding: 24px;
    background-color: #fbfbfb;
  }

  &__footer {
    display: flex;
    align-items: center;
    box-sizing: content-box;
    border-top: 1px solid #e9ecf1;

    padding: 20px 28px;
  }

  &__action-btn {
    margin-right: 30px;
  }

  &__controls-separator {
    height: 16px;
    width: 1px;
    background-color: $secondary-grey-lines-color;
    margin: 0 7px;
  }

  &__trash-icon {
    margin-left: auto;
    padding: 5px 0 5px 5px;
    cursor: pointer;
  }
}

@keyframes popup {
  from {
    bottom: 0px;
    opacity: 0;
  }

  to {
    bottom: 25px;
    opacity: 1;
  }
}
