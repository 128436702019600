@import "src/vendors/colors";
@import 'src/vendors/mixins';

.label {
  border-radius: 10px;
  padding: 2px 5px;
  min-width: 20px;
  display: inline-flex;
  justify-content: center;

  &-color-blue {
    background-color: #DDDEF6;
    color: $primary-main-color;
    cursor: pointer;

    &:hover {
      background-color: $primary-main-color;
      color: #fff;
    }
  }

  &-color-orange {
    background-color: #FEDDB7;
    color: #FC8619;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: $secondary-orange-color;

      .sidebar-trial-counter__icon {
        @include svg-colors-path-fill(white);
      }
    }
  }

  &-font-size-small {
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
  }
  &-position-moved-to-top {
    position: relative;
    top: -6px;
  }
}