.categories-number-title {
  text-decoration: underline;
  cursor: pointer;
}

.categories-wrapper {
  padding: 10px;

  &__value {
    color: #FFFFFF;
  }
}