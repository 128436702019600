@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiries-preview-table {
  border-collapse: collapse;
  width: 100%;

  &__th {
    text-align: left;
    @include mulish-text;
    line-height: 18px;
    color: $grey-text-color;

    position: sticky;
    top: 0;
    z-index: 1;
    background: white;

    padding: 14px 0;

    &:first-child {
      padding-left: 20px;
    }
  }

  &__tr {
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #F5F6FA;

      .show-more-button {
        display: flex;
      }
    }

    &--opened {
      &:hover {
        background-color: inherit;
      }
    }
  }

  &__data-cell {
    --one-line: 18.9px;
    line-height: var(--one-line);
    color: #8D8F92;
    padding: 23px 10px 23px 0;
    text-align: left;
    min-width: 50px;

    &--time {
      white-space: nowrap;
    }

    &--summary {
      margin: 0;

      display: -webkit-box;
      width: 260px;
      height: calc(var(--one-line) * 3);
      color: #1B1E22;
      overflow: hidden;
      text-overflow: ellipsis;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &:first-child {
      padding-left: 20px;
    }
  }

  &__extend-cell {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 80px);
    z-index: 10;
  }

  &__extended-info {
    margin-bottom: 37px;
  }
}