@import "src/vendors/colors";
@import "src/vendors/mixins";

.inquiries-list {
  margin-top: 15px;

  &__empty-message {
    @include mulish-text;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    color: $grey-black-color;
    margin-top: 40px;
  }
}

.inquiries-table {
  border-collapse: collapse;
  table-layout: fixed;

  &__th {
    text-align: left;
    @include mulish-text;
    line-height: 18px;
    color: $grey-text-color;

    &:first-child {
      padding-left: 20px;
    }
  }

  &__tr {
    position: relative;
    cursor: pointer;

    &:hover {
      background-color: #F5F6FA;

      .show-more-button {
        display: flex;
      }
    }

    &--opened {
      &:hover {
        background-color: inherit;
      }
    }
  }

  &__data-cell {
    padding: 23px 30px 23px 0;
    text-align: left;
    --one-line: 18.9px;
    line-height: var(--one-line);
    color: #8D8F92;

    &:first-child {
      padding-left: 20px;
    }

    &--deadline {
      color: #FF7E53;
    }

    &--no-wrap {
      white-space: nowrap;
    }

    &--summary {
      margin: 0;

      display: -webkit-box;
      width: 250px;
      height: calc(var(--one-line) * 3);
      color: #1B1E22;
      overflow: hidden;
      text-overflow: ellipsis;

      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    &--publication {
      width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  &__extend-cell {
    position: absolute;
    bottom: -15px;
    left: calc(50% - 80px);
    z-index: 10;
  }

  &__extend-cell-content {
    width: 160px;
    height: 30px;
    display: none;
    justify-content: space-between;
    padding: 8px 17px;
    align-items: center;
    color: #BBBDBE;
    font-weight: 800;
    font-size: 10px;

    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 48px;

    &:hover {
      display: flex;
    }

    svg {
      transform: rotateZ(90deg);
    }
  }

  &__extended-info {
    margin-bottom: 37px;
  }
}