@import "src/vendors/mixins";
@import "src/vendors/colors";

.filter-creation-settings-step {
  margin-top: 50px;
  padding: 0 70px 10px 70px;
  min-width: 800px;

  &__row {
    display: flex;
    align-items: center;

    &:not(&:last-child) {
      margin-bottom: 25px;
    }
  }

  &__field-title {
    @include mulish-text;
    font-weight: 600;
    font-size: 14px;
    color: $grey-text-secondary;
    min-width: 280px;
    width: 280px;
    margin-right: 10px;
  }

  &__field {
    flex: 1;

    .input__field {
      width: 100%;
    }

    &--range {
      padding: 0 10px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
  }
}