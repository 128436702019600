.show-more-button {
  width: 160px;
  height: 30px;
  display: none;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;

  color: #BBBDBE;
  font-size: 10px;
  font-weight: 800;

  z-index: 10;

  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  border-radius: 48px;

  &:hover {
    display: flex;
  }

  svg {
    transform: rotateZ(-90deg);
  }
}