@import 'src/vendors/colors';
@import 'src/vendors/mixins';

.filter-creation-steps {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &__step {
    display: flex;
    align-items: center;
    padding: 0 2px 8px 2px;
    border-bottom: 2px solid $secondary-grey-lines-color;

    &:not(&:last-child) {
      margin-right: 70px;
    }
    cursor: pointer;
  }

  &__step--active {
    border-color: $primary-main-color;

    .filter-creation-steps {
      &__step-index {
        background: $light-main-color;
        border: 1px solid #e0e6f5;
      }

      &__step-title {
        color: $primary-main-color;
      }
    }
  }

  &__step--disabled {
    cursor: default;
    pointer-events: none;
  }

  &__step-index {
    @include mulish-text;
    font-size: 10px;
    line-height: 10px;
    color: $grey-black-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    width: 24px;
    height: 24px;
    background: $primary-grey-action-color;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
  }

  &__step-title {
    @include mulish-text;
    font-size: 14px;
    line-height: 18px;
    color: $grey-black-color;
  }
}
