@import "src/vendors/colors";
@import "src/vendors/mixins";

.filter-creation-selected-keywords {
  margin-left: 27px;

  width: 233px;
  height: 530px;
  background: #F5F5F5;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  &__title {
    @include mulish-text;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1B1E22;
    margin-bottom: 30px;

    padding: 15px 27px 0px 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;

    padding: 20px 0;
    overflow: auto;
  }

  &__item {
    margin-left: 21px;
    margin-right: 24px;

    &:not(:first-child) {
      margin-top: 28px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__delete-item-button {
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
    }
  }
}