@import "src/vendors/colors";
@import "src/vendors/mixins";

.filters-keywords-preview {
  margin-top: 40px;
  max-height: 557px;
  display: flex;
  flex-direction: column;

  &__table {
    overflow-y: scroll;
  }

  &__title {
    padding-bottom: 24px;
    font-size: 24px;
    line-height: 100%;
    border-bottom: 1px solid #F4F4F4;
    margin-bottom: 11px;
  }

  &__nothing-found {
    font-size: 16px;
    margin-top: 40px;
  }
}
