.contact-sidebar-crm-section {
  display: flex;
  flex-direction: column;

  border-top: 1px solid #ebebeb;

  background-color: #ffffff;

  &__drag-icon {
    position: absolute;
    top: 15px;
    left: 7px;

    svg {
      width: 8px;
      height: 8px;
    }
  }

  &__header {
    position: relative;
    display: flex;
    align-items: center;

    padding: 12px 18px 12px;

    &-title {
      padding-right: 8px;

      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      color: #202430;

      cursor: pointer;
    }

    &-collapse-btn {
      padding: 4px;
      cursor: pointer;

      transform: rotate(180deg) translateY(-3px);

      &--open {
        transform: rotate(0) translateY(0);
      }

      svg {
        width: 10px;
      }
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    max-height: 0;
    overflow-y: auto;

    transition: max-height 280ms ease;

    &--open {
      max-height: unset;
      overflow-y: visible;
    }
  }

  &__content {
    padding: 12px 20px 28px;
  }
}

.contact-sidebar-crm {
  &__content {
    &__add-btn {
      top: 14px;
      right: 10px;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 10px;
    }
  }
}
